<template>
  <el-row class="warp">
    <el-col :span="24" class="warp-breadcrum">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"><b>首页</b></el-breadcrumb-item>
        <el-breadcrumb-item>内容管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/otherPage/list' }">其他页面</el-breadcrumb-item>
        <el-breadcrumb-item>查看</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="24" class="warp-main">
      <el-form ref="form" :model="form" label-width="150px" class="el-form-self">

        <el-row>
          <el-col>
            <el-form-item label="类型:" prop="type">
              <div class="view-text">{{form.name}}</div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col v-if="form.videoUrl">
            <el-form-item label="音频:">
              <audio :src="downloadFileURL+form.videoUrl+'/'" controls/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="内容:">
              <Editor id="tinymce" v-model="form.content" :init="init" :disabled="true"></Editor>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="状态:" prop="status">
              <div class="view-text">{{form.status==0?'发布':'草稿'}}</div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="修改时间:" prop="formatUpdateTime">
              <div class="view-text">{{form.formatUpdateTime}}</div>
            </el-form-item>
          </el-col>
        </el-row>


        <el-row>
          <el-col>
            <el-form-item label="发布人:" prop="publisher" v-if="this.form.status==0">
              <div class="view-text">{{form.publisher}}</div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="保存人:" prop="publisher" v-if="this.form.status==1">
              <div class="view-text">{{form.publisher}}</div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="form-buttons">
          <el-form-item class="el-form-item-self">
            <el-button type="primary" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-row>
      </el-form>

    </el-col>
  </el-row>

</template>
<script>
  import tinymce from 'tinymce/tinymce'
  import 'tinymce/themes/silver/theme'
  import Editor from '@tinymce/tinymce-vue'
  import 'tinymce/icons/default/icons'
  import 'tinymce/plugins/code'
  import 'tinymce/plugins/print'
  import 'tinymce/plugins/preview'
  import 'tinymce/plugins/importcss'
  import 'tinymce/plugins/searchreplace'
  import 'tinymce/plugins/autolink'
  import 'tinymce/plugins/autosave'
  import 'tinymce/plugins/save'
  import 'tinymce/plugins/directionality'
  import 'tinymce/plugins/visualblocks'
  import 'tinymce/plugins/visualchars'
  import 'tinymce/plugins/fullscreen'
  import 'tinymce/plugins/image'
  import 'tinymce/plugins/media'
  import 'tinymce/plugins/link'
  import 'tinymce/plugins/codesample'
  import 'tinymce/plugins/table'
  import 'tinymce/plugins/charmap'
  import 'tinymce/plugins/hr'
  import 'tinymce/plugins/pagebreak'
  import 'tinymce/plugins/nonbreaking'
  import 'tinymce/plugins/anchor'
  import 'tinymce/plugins/toc'
  import 'tinymce/plugins/insertdatetime'
  import 'tinymce/plugins/advlist'
  import 'tinymce/plugins/lists'
  import 'tinymce/plugins/wordcount'
  import 'tinymce/plugins/imagetools'
  import 'tinymce/plugins/textpattern'
  import 'tinymce/plugins/noneditable'
  import 'tinymce/plugins/help'
  import 'tinymce/plugins/charmap'
  import 'tinymce/plugins/quickbars'
  import 'tinymce/plugins/lists'
  import 'tinymce/plugins/indent2em'
  import 'tinymce/icons/default/icons'
  import {reqOtherPageById}  from '../../../api/otherPage-api';
  import {downloadFileURL} from '../../../api/system-api'
  export default {
    components: {
      Editor
    },
    data() {
      return {
        downloadFileURL:downloadFileURL,
        editor:'',
        defaultMsg: '',
        form: {
          title : '',
          publishTime  : '',
          publisher  : '',
          status : '',
          content : '',
        },
        init: {
          language_url: '/static/tinymce/langs/zh_CN.js',
          language: 'zh_CN',
          skin_url: '/static/tinymce/skins/ui/oxide',
          content_css: "/static/tinymce/skins/content/default/content.css",
          height: 800,
          font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;',
          plugins: 'code print indent2em preview importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image media link codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars',
          toolbar: 'undo redo |code bold italic underline strikethrough indent2em | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak charmap | fullscreen  preview print | image media  pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
        }
      }
    },
    methods: {
      getOtherPageById(id){
        let that=this;
        reqOtherPageById({id:id}).then((res)=>{
            this.form = Object.assign({}, res.data.data);
            that.form.title=res.data.data.title;
            that.defaultMsg=res.data.data.content;
            that.form.publishTime=res.data.data.publishTime;
            that.form.publisher=res.data.data.publisher;
            that.form.status=res.data.data.status;
            that.form.createTime=res.data.data.createTime;
          })
      }
    },
    mounted() {
      tinymce.init({});
      var row=this.$route.query;
      var id=row.id;
      this.getOtherPageById(id);

    }
  }
</script>
